import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import LoggedPage from '@components/containers/LoggedPage';
import Card from '@components/containers/Card';
import FlexBox from '@components/containers/FlexBox';
import PDFViewer from '@components/modules/PDFViewer';
import Loader from '@components/elements/Loader';
import { bankIdRequests } from '@constants/bankId';
import urls from '@constants/urls';
import getUrl from '@utils/getUrl';
import BankIdButtonGroup from '@components/modules/BankIdButtonGroup';

const Signera = ({ accountId, bankAccountId }) => {
  const loading = useSelector((state) => state.documents.loading);
  const documentUrl = useSelector((state) => state.documents.documentUrl);
  const documentType = useSelector((state) => state.documents.documentType);

  return (
    <LoggedPage
      title="Signera autogiromedgivande"
      previousUrl={getUrl(urls.HOME.AUTOGIRO.VALID_BANK_ACCOUNT, { accountId })}
      fullWidth
    >
      {loading ? (
        <Loader />
      ) : (
        <Card>
          <FlexBox
            alignItems="stretch"
            breakSize="tablet"
            maxHeight="55vh"
            maxHeightLargeScreen="60vh"
          >
            {documentUrl && <PDFViewer url={documentUrl} />}
          </FlexBox>
          <FlexBox justifyContent="center" margin="regular">
            <BankIdButtonGroup
              requestType={bankIdRequests.SIGN_DOCUMENT}
              direction="row"
              payload={{ accountId, bankAccountId, documentType }}
            />
          </FlexBox>
        </Card>
      )}
    </LoggedPage>
  );
};

Signera.propTypes = {
  accountId: PropTypes.string.isRequired,
  bankAccountId: PropTypes.string.isRequired,
};

export default Signera;
